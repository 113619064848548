import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

const Policy = () => {


      useEffect(() => {
      document.getElementById('lang-switch').href = 'https://e-multiconent.com/privacy-policy/'
       });
  return(
  <>
  <Helmet titleTemplate="Polityka prywatności | e-multicontent.pl">
    <meta name="robots" content="noindex, nofollow" />
    <title>Polityka prywatności | e-multicontent.pl</title>
    <meta name="description" content={'Polityka prywatności - e-multiconent dla e-commerce'}/>
    <meta name='image' content={'https://e-multicontent.pl/img/meta/privacy-meta.jpg'} />
    <meta name='keywords' content={'copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />
    <meta property="og:description" content={'✍🏻 Piszemy dla 🛒 e-commerce w języku polskim i w innych językach uwzględniając SEO - opisy produktowe, kategorii, wpisy blogowe, treści na www.'}/>
    <meta property='og:title' content={'Polityka prywatności | Agencja copywriterska Wrocław | e-multicontent.pl'} />
    <meta property='og:image' content={'https://e-multicontent.pl/img/meta/privacy-meta.jpg'} />
    <meta property="og:url" content={'https://e-multicontent.pl/polityka-prywatnosci/'}/>

    <link rel="alternate" href="https://e-multicontent.com/privacy-policy/" hrefLang="en-gb" />
    <link rel="alternate" href="https://e-multicontent.com/privacy-policy/" hrefLang="en-us" />
    <link rel="canonical" href="https://e-multicontent.pl/polityka-prywatnosci/"  />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="e-multicontent.pl" />
    <meta property="twitter:url" content="https://e-multicontent.pl/polityka-prywatnosci/" />
    <meta name="twitter:title" content="Polityka prywatności | Agencja copywriterska Wrocław | e-multicontent.pl" />
    <meta name="twitter:description" content="✍🏻 Piszemy dla 🛒 e-commerce w języku polskim i w innych językach uwzględniając SEO - opisy produktowe, kategorii, wpisy blogowe, treści na www." />
    <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />
    <script type='application/ld+json'>
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://e-multicontent.pl/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Polityka prywatności",
        "item": "https://e-multicontent.pl/polityka-prywatnosci/"
      }]
    })}
    </script>

  </Helmet>
  <Layout>
  <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Polityka prywatności</b></span>
  </nav>
    <section className="section">
      <div className="container">
        <div className="content" style={{margin:'2.5%'}}>
        <div style={{textAlign:'center'}}>
          <img src="/img/privacy.svg" alt="polityka prywatności - Agencja Copywriterska Wrocław - tłumaczenia i teksty, seo dla stron internetowych, e-commerce i na marketplace" style={{width: '200px', }}/>
          <h1 className="subtitle" style={{color:'#36b3d2', textDecoration: 'underline',fontVariant:'all-small-caps'}}><b>Polityka prywatności i polityka cookies e-multicontent</b></h1>
        </div>

          <p>
          E-multicontent prowadząc stronę pod adresem <Link to="/">e-multicontent.pl</Link> dokłada wszelkich starań w celu zapewnienia poszanowania Państwa prywatności i ochrony udzielonych informacji oraz danych osobowych podczas korzystania z Witryny i newslettera oraz w tym celu podejmuje wszelkie niezbędne działania.
          Korzystanie ze Strony internetowej pod adresem <Link to="/">e-multicontent.pl</Link> oznacza akceptację przedstawionych warunków <b>polityki prywatności i polityki cookies</b>.
          </p>

          <h2 className="subtitle" style={{textAlign:'center'}}>§ 1</h2>

          <p>
          Niniejsza Polityka Prywatności i Polityka Cookies określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników oraz plików Cookies, a także innych technologii pojawiających się na Stronie e-multicontent.pl.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 2 <br></br>KTO JEST ADMINISTRATOREM DANYCH?</h2>

          <p>
          Administratorem strony oraz danych osobowych przekazywanych jest Innovision Group A.Filip Sp. k. NIP 8971898232, KRS: 0000932139
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 3 <br></br>ZABEZPIECZENIE DANYCH</h2>

          <p>
          Dane osobowe Użytkowników są przechowywane i chronione z należytą starannością, zgodnie z procedurami wewnętrznymi Administratora. Administrator przetwarza dane Użytkowników przy użyciu odpowiednich środków technicznych i organizacyjnych spełniających wymagania przepisów prawa powszechnie obowiązującego. Środki te mają na celu przede wszystkim zabezpieczenie danych osobowych przed ich utratą, naruszeniem integralności danych oraz dostępem osób nieupoważnionych. Dostęp do danych mają jedynie osoby upoważnione, które są zobowiązane do zachowania tych danych w tajemnicy lub podmioty, którym udostępniono lub powierzono przetwarzanie danych na podstawie przepisu prawnego lub umowy.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 4</h2>

          <p>
          Dane osobowe Użytkownika nie Bedą profilowane i wykorzystywane do zautomatyzowanego podejmowania decyzji, mających wpływ na prawa i obowiązki czy wolności Użytkownika w rozumieniu RODO.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 5</h2>

          <p>
          Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce Prywatności. Przyczyną zmian mogą być rozwój technologii internetowej, zmiany w powszechnie obowiązującym prawie czy też rozwój strony poprzez np. korzystanie z nowych narzędzi przez Administratora. Aktualizacja Polityki zostanie oznaczona na stronie.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 6<br></br>CEL PRZETWARZANIA DANYCH I PODSTAWA PRAWNA</h2>

          <p>
          Celem przetwarzania danych jest świadczenie usługi newslettera, udzielenie odpowiedzi na pytanie, obsługi przesłanego zapytania, udzielenia wstępnych informacji związanych z nawiązaniem współpracy oraz przedstawienie oferty, a także bieżący kontakt z Państwem w powyższym zakresie.
          Dane osobowe Użytkownika na Stronie Administratora mogą być przetwarzane w następujących celach i podstawach prawnych:
          <ul>
            <li>Ustalenia, dochodzenia lub obrony przed roszczeniami — na podstawie art. 6 ust. 1; </li>
            <li>Kontaktu telefonicznego w sprawach związanych z udzieleniem odpowiedzi na pytanie oraz w celu przedstawienia oferty i marketingu bezpośredniego- na podstawie art. 6 ust. 1 lit a RODO (zgoda) oraz na podstawie art. 6 ust. 1 lit f RODO (prawnie uzasadniony interes administratora);</li>
            <li>Archiwalnym i dowodowym, na potrzeby zabezpieczenia informacji, które mogą służyć wykazywaniu faktów — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes administratora);</li>
            <li>Wykorzystywania cookie na Stronie i jej podstronach — na podstawie art. 6 ust. 1 lit. a RODO (zgoda);</li>
            <li>Zarządzania Stroną internetową Administratora— na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes administratora);</li>
            <li>Wysyłania newslettera – na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes administratora polegający na przetwarzaniu danych w celu marketingu bezpośredniego) oraz na podstawie ustawy o świadczeniu usług drogą elektroniczną;</li>
            <li>Obsługi fanpage pod nazwa emulticontent.ecommerce na Facebooku i wchodzenia w interakcje z użytkownikami - na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes administratora);</li>
            <li>Obsługi konta pod nazwą multicontent na LinkedIn i wchodzenia w interakcje z użytkownikami - na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes administratora).</li>
          </ul>
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 7</h2>

          <p>
          Podanie danych zawartych w formularzy kontaktowym jest dobrowolne, lecz konieczne i niezbędne dla realizacji usługi newsletteru oraz udzielenia odpowiedzi na pytanie.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 8<br></br>OKRESY PRZECHOWYWANIA DANYCH</h2>
          <p>
          Zgodnie z obowiązującymi przepisami prawa E-MULTICONTENT może przechowywać Państwa dane osobowe jedynie przez okres niezbędny do realizacji celów, do których są wykorzystywane. Tym samym Państwa dane osobowe przechowywane będą przez okres niezbędny do realizacji usługi newsletteru oraz udzielenia odpowiedzi na pytanie.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 9</h2>
          <p>
          Dane Użytkownika będą przekazywane do innych podmiotów z którymi łączą nas umowy powierzenia danych osobowych oraz którym udostępniamy dane na podstawie obowiązujących przepisów w tym podwykonawcy, podmioty zajmujące się hostingiem (przechowywaniem) danych osobowych dla Administratora, podmioty świadczące usługi w zakresie obsługi i wsparcia systemów teleinformatycznych, podmioty świadczące usługi marketingowe, prawne i informatyczne. Ponadto podane przez Państwa informacje mogą zostać udostępnione właściwym organom władzy publicznej, jeżeli wymagają tego obowiązujące przepisy prawa.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 10<br></br>PRAWA UŻYTKOWNIKÓW</h2>

          <p>
           Przysługują Państwu następujące uprawnienia w zakresie przetwarzania danych: 
          <ul>
            <li>prawo uzyskania informacji jakie Państwa dane osobowe przechowujemy oraz dostępu do nich,</li>
            <li>prawo sprostowania i uzupełnienia Państwa danych osobowych, które są nieprawidłowe lub niepełne,</li>
            <li>prawo do żądania usunięcia Państwa danych osobowych, jeśli nie ma uzasadnionego powodu dalszego ich przechowywania,</li>
            <li>prawo do żądania ograniczenia przetwarzania Państwa danych osobowych, w przypadku, gdy kwestionują Państwo nasze prawo do ich wykorzystania,</li>
            <li>prawo do wniesienia sprzeciwu odnośnie przetwarzania Państwa danych osobowych na podstawie prawnie uzasadnionego interesu Administratora,</li>
            <li>prawo do żądania przeniesienia Państwa danych osobowych do innego administratora, jeśli jest to technicznie możliwe (na zasadach określonych w art. 20 RODO),</li>
            <li>prawo do zgłoszenia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych,</li>
            <li>prawo do bycia zapomnianym, jeśli dalsze przetwarzanie danych nie będzie przewidziane przez aktualnie obowiązujące przepisy prawa.</li>
          </ul>
          Administrator informuje, że uprawnienia te nie są bezwzględne i nie przysługują w odniesieniu do wszystkich czynności przetwarzania danych osobowych Użytkownika.
          <br></br><br></br>
          Jeżeli Użytkownik wyraził zgodę na określone działanie, zgoda taka może być w każdym czasie cofnięta. Cofnięcie zgody pozostaje bez wpływu na przetwarzanie danych, którego dokonano na podstawie zgody przed jej cofnięciem.
          <br></br><br></br>
          W celu realizacji swoich praw Użytkownik może zwrócić się do Administratora poprzez formularz kontaktowy wskazując zakres swoich żądań. Odpowiedź zostanie udzielona w terminie nie późniejszym niż 30 dni od dnia wpłynięcia żądania i jego uzasadnienia, chyba że uzasadnione będzie wydłużenie tego terminu zgodnie z RODO.
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 11<br></br>PLIKI COOKIES</h2>

          <p>
          Od momentu połączenia się Użytkownika z serwisem zaczynamy wykorzystywać pliki cookie. Są to to małe pliki tekstowe przechowywane na urządzeniu końcowym (komputerze) Użytkownika strony internetowej w czasie, którym z niej korzysta. Ich celem jest zapamiętywanie preferencji użytkownika oraz zbieranie informacji statystycznych odnośnie wykorzystywania witryny internetowej.
          <br></br><br></br>
          Na stronie e-multicontent.pl wykorzystujemy następujące rodzaje plików cookie: 
          <ul>
            <li>Pliki cookie niezbędne do funkcjonowanie strony - odpowiedzialne za prawidłowe działanie kluczowych procesów serwisu. Zablokowanie tych plików cookie może wpłynąć negatywnie na prawidłowe funkcjonowanie strony.</li>
            <li>Funkcjonalne pliki cookie – zachowują wybrane przez użytkownika ustawienia i pozwalają na personalizację interface’u, np. zapamiętywanie danych wpisanych do formularza podczas przechodzenia pomiędzy stronami witryny, wersję językową strony, wygląd strony.</li>
            <li>Pliki cookies służące do analityki internetowej - pomagają nam zrozumieć, w jaki sposób odwiedzający korzystają z naszego serwisu, dzięki czemu jesteśmy w stanie poprawiać funkcjonowanie naszej strony. Za ich pomocą zbieramy na przykład informacje o tym, które strony są najczęściej odwiedzane przez naszych klientów, skąd trafiają oni na naszą stronę, które funkcjonalności strony są przez nich najczęściej wybierane. Wykorzystywane są do takich działań jak monitorowanie i prowadzenie statystyk wykorzystania naszej strony, rozpoznawanie i eliminowanie błędów na naszej stronie, sprawdzanie skuteczności reklam i praca nad tym, aby były odpowiednio dobierane, testowanie funkcjonalności i układów naszej strony.</li>
          </ul>
          Przeprowadzamy analizę statystyk odbiorców naszej witryny przy użyciu oprogramowania <b>Google Analytics</b>, przekazuje ono anonimowe informacje o użytkownikach naszej witryny, które używamy w celu jej usprawniania pod kątem technologicznym jak i treści witryny.
          <br></br>
          Według informacji Google zawartych w <a href="https://privacy.google.com/businesses/adsservices/" rel="noopener noreferrer" target="_blank">Google Ads Data Protection Terms: Service Information</a>, Google Analytics zbiera następujące typy danych osobistych:
          <ul>
            <li>Identyfikatory online włączając pliki cookies.</li>
            <li>Adresy IP oraz identyfikatory urządzeń.</li>
            <li>Identyfikatory klientów.</li>
          </ul>
            Dane wygenerowane przez oprogramowanie google analytics, zostają udostępnione firmie Google.
            <br></br>
            Każdy indywidualny cookie składa się z czterech podstawowych części:
            <ul>
            <li>nazwa strony WWW: nazwa domeny lub subdomeny, która ustawiła cookie;</li>
            <li>nazwa cookie: cookie ma nazwę, unikatową na stronie, która je ustawiła;</li>
            <li>termin ważności: ważność niektórych plików cookie wygasa po zamknięciu przeglądarki (tzw. cookie sesji), inne cookie zostaną automatycznie usunięte dopiero po osiągnięciu daty ważności, która została ustawiona (tzw. trwałe);</li>
            <li>wartość: to informacje w pliku cookie, których strona WWW używa do „zapamiętania” poprzedniej wizyty.</li>
            </ul>
            W plikach cookie przechowujemy podstawowe informacje o Użytkownikach (np. identyfikator) oraz informacje potrzebne do optymalizacji i poprawnego wyświetlania treści zawartych na stronie. Informacje te służą do:
          <ul>
            <li>rozpoznawania użytkowników logujących się w serwisie,</li>
            <li>rejestrowania odwiedzin Użytkowników, co pozwala zebrać dane pomocne we wprowadzaniu usprawnień w treści i w sposobie nawigacji strony.</li>
            Strona nie zbiera w sposób automatyczny żadnych danych, z wyjątkiem danych zawartych w plikach cookies podczas samego korzystania z Witryny. 
          </ul>
          Podczas pierwszego wejścia na Stronę Użytkownik musi wyrazić zgodę na cookies lub podjąć inne, możliwe działania wskazane w komunikacie, żeby móc dalej korzystać z zawartości Strony. Korzystanie ze Strony oznacza wyrażenie zgody.  Jeżeli nie chcesz takiej zgody wyrazić – opuść Stronę. Zawsze też, możesz zmienić ustawienia swojej przeglądarki, wyłączyć lub usunąć pliki cookies. W zakładce „pomoc” w  przeglądarce Użytkownika znajdują się niezbędne informacje.
          <br></br><br></br>
          W każdym wypadku mogą Państwo zablokować instalowanie plików cookies lub usunąć stałe pliki cookies, wykorzystując stosowne opcje Państwa przeglądarki internetowej.  Zablokowanie plików cookie może wpłynąć na poprawne funkcjonowanie niektórych procesów na stronie oraz dostosowanie jej do preferencji użytkownika. 
          <br></br><br></br>
          Obok plików cookies Strona może również gromadzić dane zwyczajowo zbierane przez administratorów systemów internetowych w ramach tzw. logów lub plików dziennika. Informacje zawarte w logach mogą obejmować m.in. Państwa adres IP, rodzaj platformy i przeglądarki internetowej, dostawcę Internetu oraz adres strony, z której weszliście Państwo na Witrynę. Dane te nie będą łączone z przekazanymi przez Państwa danymi osobowymi.
          <br></br><br></br>
          Dane chronione są poprzez certyfikat szyfrujący SSL zainstalowany na naszej stronie, dodatkowo wszelkie punkty dostępu do danych chronione są specjalnymi hasłami w formacie MD5 hash.
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 12</h2>

          <p>
          Na stronie mogą pojawiać się linki odsyłające do innych stron internetowych. Będą one otwierać się w nowym oknie przeglądarki lub w tym samym oknie. Administrator nie odpowiada za treści przekazywane przez te strony.
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 13</h2>

          <p>
          W celu korzystania ze strony internetowej Administratora niezbędne jest posiadanie:
          <ul>
          <li>Urządzenia z dostępem do internetu;</li>
          <li>Aktywnej skrzynki elektronicznej odbierającej wiadomości e-mail;</li>
          <li>Przeglądarki internetowej umożliwiającej wyświetlenie stron www;</li>
          <li>Oprogramowania umożliwiającego odczyt treści w przedstawionych formatach np. pdf, video , mp3, mp4.</li>
          </ul>
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 14</h2>

          <p>
          Korzystanie ze Strony wiąże się̨ z przesyłaniem zapytań́ do serwera, na którym przechowywana jest Strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera. Logi obejmują m.in. adres IP Użytkownika, datę̨ i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym, z jakiego korzysta Użytkownik.  Logi zapisywane i przechowywane są na serwerze. Logi serwera służą do administrowania Stroną, a ich treść nie jest ujawniana nikomu poza osobami i podmiotami upoważnionymi do administrowania serwerem. Administrator nie wykorzystuje w żaden sposób logów serwera do identyfikacji Użytkownika.
          </p>


          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 15<br></br>KONTA W SOCIAL MEDIACH</h2>

          <p>
          Administratorem danych osobowych użytkownika na Facebook fanpage pod nazwą EMULTICONTENT.ECOMMERCE jest Innovision Group A.Filip Sp. k.
          </p>

          <p>
          Administratorem danych osobowych użytkownika na koncie LINKEDIN pod nazwą EMULTICONTENT jest jest Innovision Group A.Filip Sp. k.
          </p>

          <p>
          Dane osobowe Użytkownika podawane na Fanpage, Koncie Bedą przetwarzane w celu administrowania i zarzadzania Fanpagem, Kontem komunikowania się̨ z Użytkownikiem, wchodzenia w interakcje, kierowania do Użytkownika treści marketingowych i tworzenia społeczności Fanpage.
          </p>

          <p>
          Podstawą ich przetwarzania jest zgoda Użytkownika i prawnie uzasadniony interes administratora polegający na wchodzeniu w interakcje z Użytkownikami i Obserwującymi Fanpage. Użytkownik dobrowolnie decyduje się̨ na polubienie/obserwowanie Fanpage/ Konta.
          </p>

          <p>
          W każdej chwili, Użytkownik może przestać obserwować Fanpage/ Konto. Administrator nie będzie jednak wówczas wyświetlać Użytkownikowi żadnych treści pochodzących od Administratora a związanych z Fanpage, Kontem.
          </p>

          <p>
          Administrator widzi dane osobowe Użytkownika, takie jak mogą. Imię, nazwisko czy informacje ogólne, które Użytkownik umieszcza na swoich profilach, jako publiczne. Przetwarzanie pozostałych danych osobowych dokonywane jest przez portal społecznościowy Facebook, LinkedIn i na warunkach zawartych w jego regulaminach.
          </p>

          <p>
          Dane osobowe Użytkownika Bedą przetwarzane przez okres prowadzenia/istnienia Fanpage/ Konta na podstawie zgody wyrażonej poprzez polubienie/klikniecie „Obserwuj” Fanpage/ Konto lub wejście w interakcje mogą. Pozostawienie komentarza, wysłanie wiadomości oraz w celu realizacji prawnie uzasadnionych interesów Administratora, tj. marketingu własnych produktów lub usług albo obrony przed roszczeniami.
          </p>

          <p>
          Dane osobowe Użytkownika mogą być udostępnianie innym odbiorcom danych, takim jak portal Facebook, portal LinkedIn, współpracującym agencjom reklamowym lub innym podwykonawcom obsługującym Fanpage, Konto Administrator.
          </p>

          <p>
          Pozostałe uprawnienia Użytkownika opisane są w niniejszej polityce prywatności. Dane Użytkownika mogą być przekazywane do państw trzecich zgodnie z regulaminem Facebooka/ LinkedIn. Dane nie Bedą jednak przetwarzane w sposób zautomatyzowany w rozumieniu RODO (mając negatywny wpływ na prawa i wolności Użytkownika).
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 16</h2>

          <p>
          Treści przedstawione na Stronie nie stanowią porad czy wskazówek specjalistycznych (np. edukacyjnych) i nie odnoszą się do konkretnego stanu faktycznego. Jeśli Użytkownik chce uzyskać pomoc w konkretnej sprawie, powinien skontaktować się z osobą uprawnioną do udzielania takich porad lub z Administratorem na podane dane kontaktowe. Administrator nie ponosi odpowiedzialności za wykorzystanie treści zawartych na Stronie lub działań czy zaniechań podejmowanych na ich podstawie.
          </p>

          <p>
          Wszystkie treści umieszczone na Stronie stanowią przedmiot praw autorskich określonych osób i/lub Administratora (np. zdjęcia, teksty, inne materiały, itp.). Administrator nie wyraża zgody na kopiowanie tychże treści w całości lub w części bez jego wyraźnej, uprzedniej zgody.
          </p>

          <p>
          Administrator informuje niniejszym Użytkownika, że jakiekolwiek rozpowszechnianie treści udostępnionych przez Administratora stanowi naruszenie przepisów prawa i może rodzić odpowiedzialność cywilną lub karną. Administrator może także domagać się stosownego odszkodowania zadośćuczynienia z tytułu poniesienia strat materialnych lub niematerialnych zgodnie z obowiązującymi przepisami.
          </p>

          <p>
          Administrator nie ponosi odpowiedzialności za wykorzystanie materiałów dostępnych na stronie w sposób niezgodny z prawem.
          </p>

          <p>
          Treści umieszczone na Stronie są aktualne na dzień ich zamieszczenia, chyba że wskazano inaczej.
          </p>

          <br></br>
          <br></br>

          <p><b>Data publikacji Polityki Prywatności: 14.07.2021</b></p>

          <p><b>Data ostatniej aktualizacji: 3.11.2021</b></p>


          <br></br>
          <Link className="btn" to="/"> Wróć na stronę. </Link>

        </div>
      </div>
    </section>
  </Layout>
  </>
)
}
export default Policy;
